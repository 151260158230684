<template>
<div class="bg-footer row justify-content-center">
  <div class="d-flex align-items-center text-center">
      <p id="desktop" class="title">Tu coche te avala sin retirada de vehículo, sin cambio de <br> titularidad y sin alquiler por conducir.</p>
      <p id="mobile" class="title">Tu coche te avala sin retirada de vehículo, sin cambio de titularidad y sin alquiler por conducir.</p>
  </div>
</div>
</template>

<script>
export default {
    name: 'Slogan'
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-size: 1.9rem;
  line-height: auto;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bg-footer {
    background-size: cover;
    background-color: $blue;
    height: 145px;
    color: $white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
    #desktop {
      display: none;
      visibility: hidden;
    }
    .bg-footer {
      .title {
        padding: 0.20rem;
        font-size: 1.4rem !important;
        line-height: 35px;
      }
    }

}

@include media-breakpoint-between(md,lg) {
  #mobile {
    display: none;
    visibility: hidden;
    
  }
  .bg-footer {
    h2 {
      font-size: 1.5rem;
      line-height: 45px;
    }
  }

}

@include media-breakpoint-between(xl,xxl) {
  #mobile {
    display: none;
    visibility: hidden;
  }
  .bg-footer {
    h2 {
      font-size: 1.9rem !important;
      line-height: 50px;
    }
  }
}

</style>