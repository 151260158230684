<template>
  <div>
    <QuienesSomosS1/>
  </div>
</template>
<script>
// @ is an alias to /src
import QuienesSomosS1 from '@/components/QuienesSomos/QuienesSomosS1.vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'QuienesSomos',
  components: {
    QuienesSomosS1
  },
  setup() {
    useMeta({
      title:'Un equipo de personas',
      meta: [
        {name:'robots', content:'noindex'},
        {name:'description', content:'Ibancar es una empresa que otorga préstamos personales con aval de coche. Hemos logrado transformar el sector, convirtiendo el proceso 100% online y sin cambiar la titularidad del coche.'},
        {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
      ],
      link: [
        { href: 'https://ibancar.com/quienes-somos', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/quienes-somos', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
}
</script>